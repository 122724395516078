import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import styled from 'styled-components'



const IndexPage = () => (
  <Layout>
    <SEO title="Hector le chat" keywords={[`chat`, `cat`, `hector`]} />
<Image/>
  </Layout>
)

export default IndexPage